import { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    // socket io
    const [message, setMessage] = useState("");
    const [socket, setSocket] = useState();
    const [skTicket, setSkTicket] = useState("");
    const [updateUserCount, setUpdateUserCount] = useState();
    const [notification, setNotification] = useState();
    const [titlePage, setTitlePage] = useState("");
    const [buildingName, setBuildingName] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [buildingId, setBuildingId] = useState("");

    return (
        <DataContext.Provider
            value={{
                titlePage,
                setTitlePage,
                socket,
                setSocket,
                message,
                setMessage,
                skTicket,
                setSkTicket,
                updateUserCount,
                setUpdateUserCount,
                notification,
                setNotification,
                buildingName,
                setBuildingName,
                startTime,
                setStartTime,
                endTime,
                setEndTime,
                buildingId,
                setBuildingId,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
