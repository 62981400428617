import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { statusTemplate } from '../../components/utils';
import FormatDate from '../Report/FormatDate';
import { getListStatus } from '../../store/product/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../Report/LoadingSpinner';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import Intersection from '../Plan/intersection';
import { getDataAll } from '../../store/teams/slice';
import { Paginator } from 'primereact/paginator';

const TeamDetail = ({
  dashboardTeamsDetail,
  onStatusChange,
  onTeamChange,
  onPaginationChange,
}) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    buildingName: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const teamData = useSelector((state) => state.Teams.teamsData, shallowEqual);

  useEffect(() => {
    dispatch(
      getDataAll({
        page: 1,
        limit: 50,
      })
    );
  }, [dispatch]);

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
  };

  const listStatus = useSelector(
    (state) => state.Product.listStatus,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getListStatus());
  }, [dispatch]);

  const uniqueStatuses = [...new Set(listStatus?.data)];

  const options = uniqueStatuses.map((status) => ({
    name: status,
    code: status,
  }));

  const optionTeams = teamData?.data?.items.map((e) => e.name);

  const onPageChange = (event) => {
    const newFirst = event.first;
    const newRows = event.rows;

    setFirst(newFirst);
    setRows(newRows);
    if (onPaginationChange) {
      onPaginationChange(newFirst, newRows);
    }
  };

  return (
    <div className='p-3 bg-white border-round-lg h-full'>
      <div className='flex sm:align-items-center sm:justify-content-between mt-1 sm:flex-row flex-column gap-2'>
        <p className='font-bold text-500'>Chi tiết tổ đội sản xuất</p>
        <div className='flex justify-content-end gap-2 mb-2 sm:w-6'>
          <Dropdown
            value={selectedTeam}
            options={optionTeams}
            placeholder='Lọc tổ đội'
            className='border-300 w-full flex-1'
            filter
            filterPlaceholder='Tìm kiếm'
            showClear
            onChange={(e) => {
              setSelectedTeam(e.value);
              onTeamChange(e.target.value);
            }}
          />
          <Dropdown
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.value);
              onStatusChange(e.value);
            }}
            showClear
            options={options}
            optionLabel='name'
            placeholder='Lọc trạng thái'
            checkmark={true}
            highlightOnSelect={false}
            className='border-300 w-full flex-1'
          />
        </div>
      </div>
      {dashboardTeamsDetail && dashboardTeamsDetail.data ? (
        <div>
          <DataTable
            value={dashboardTeamsDetail?.data?.items}
            rowClassName='custom-row-class-factory'
            emptyMessage='Không có dữ liệu'
            onRowToggle={(e) => setExpandedRows(e.data)}
            scrollable
            scrollHeight='50vh'
            filters={filters}
            onFilter={(e) => setFilters(e.filters)}>
            <Column
              header='STT'
              body={(rowData, options) => (
                <div className='text-center'>{options.rowIndex + 1}</div>
              )}
              alignHeader={'center'}
              align={'center'}></Column>
            <Column
              header='Công trình'
              field='buildingName'
              style={{ minWidth: '150px' }}
              filter></Column>
            <Column
              header='Hạng mục'
              field='categoryName'
              style={{ minWidth: '150px' }}
              filter></Column>
            <Column
              header='Mã nhà máy'
              field='factoryCode'
              style={{ minWidth: '150px' }}
              filter></Column>
            <Column
              header='Từ ngày'
              field='timeStart'
              body={(rowData) => <span>{FormatDate(rowData.timeStart)}</span>}
              style={{ minWidth: '100px' }}
              align='center'
              alignHeader='center'
              sortable></Column>
            <Column
              header='Đến ngày'
              field='timeEnd'
              body={(rowData) => <span>{FormatDate(rowData.timeEnd)}</span>}
              style={{ minWidth: '120px' }}
              sortable
              align='center'
              alignHeader='center'></Column>
            <Column
              header='SL'
              field='quantityMade'
              style={{ minWidth: '70px' }}></Column>
            <Column
              header='ĐVT'
              field='unit'
              style={{ minWidth: '50px' }}
              filter></Column>
            <Column
              header='Trạng thái'
              field='status'
              body={statusTemplate}
              alignHeader={'center'}
              align={'center'}
              style={{ minWidth: '100px' }}
              filter></Column>
            <Column
              header='Định vị'
              body={(rowData) => (
                <div className='text-center'>
                  <Button
                    onClick={() => handleTeamProgress(rowData)}
                    icon='pi pi-map-marker'
                    rounded
                    text
                    severity='secondary'
                    aria-label='Bookmark'
                    style={{ color: 'black' }}
                  />
                  <p>{rowData.teamWorking}</p>
                </div>
              )}
              alignHeader={'center'}
              align={'center'}
              style={{ minWidth: '100px' }}></Column>
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={dashboardTeamsDetail?.data?.totalItems}
            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            onPageChange={onPageChange}
          />
          <Intersection
            visible={dialogLocationVisible}
            onHide={() => setDialogLocationVisible(false)}
            factoryCode={selectFactoryCode}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default TeamDetail;
