import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import '../../assets/scss/theme-base/QuantityTemplate.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import '../../assets/scss/theme-base/index.css';
import tabMenuTemplate from '../../assets/templete/tabMenuTemplete';
import {
  statusTemplate,
  formatDate,
  formatNumber,
} from '../../components/utils';
import {
  getOrder,
  setDataNull2,
  exportExcel3,
  getBuilding,
  resetProductionExcel,
} from '../../store/order/slice';
import Intersection from '../Plan/intersection';
import { site } from '../../api/url_helper';
import { Paginator } from 'primereact/paginator';
import LoadingSpinner from '../Report/LoadingSpinner';
import { IconField } from 'primereact/iconfield';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { exportQuantityPDF } from './exportQuantityPDF';
import { Image } from 'primereact/image';
import { Menu } from 'primereact/menu';
import ModalExcel from './ModalExcel';
import { FilterMatchMode } from 'primereact/api';

export default function Manufacture() {
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [categoryValue, setCategoryValue] = useState('');
  const [factoryCodeValue, setFactoryCodeValue] = useState('');
  const [buildingValue, setBuildingValue] = useState();
  const [orderCodeValue, setOrderCodeValue] = useState('');
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [category, setCategory] = useState(null);
  const [factoryCode, setFactoryCode] = useState(null);
  const [building, setBuilding] = useState(null);
  const [orderCode, setOrderCode] = useState(null);
  const [excelFileUrl, setExcelFileUrl] = useState('');
  const downloadLink = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuRight = useRef(null);
  const [dialogImportVisible, setDialogImportVisible] = useState(false);
  const defaultImageUrl =
    'https://static.thenounproject.com/png/1269202-200.png';

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'Building.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryMaterial: { value: null, matchMode: FilterMatchMode.CONTAINS },
    materialName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalActualDelivery: { value: null, matchMode: FilterMatchMode.CONTAINS },
    intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    bomProductionDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    startedDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    endedDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    // Thêm các trường khác nếu cần
  });

  const {
    orderData,
    dataBuilding,
    exportExcelData3,
    exportExcelError3,
    importData3,
    loadingExportExcel,
  } = useSelector(
    (state) => ({
      orderData: state.Production.orderData,
      dataBuilding: state.Production.dataBuilding,
      exportExcelData3: state.Production.productionExportExcel.data,
      exportExcelError3: state.Production.productionExportExcel.error,
      loadingExportExcel: state.Production.productionExportExcel.loading,
      importData3: state.Production.importData3,
    }),
    shallowEqual
  );

  useEffect(() => {
    let status = 'Tất cả';
    if (orderData) {
      status = orderData.data.listStatus[tabMenuActive]?.label;
    }
    dispatch(
      getOrder({
        page: (first + rows) / rows,
        limit: rows,
        status,
        category: category,
        factoryCode: factoryCode,
        building: building,
        orderCode: orderCode,
        current: 'production',
      })
    );
  }, [
    dispatch,
    first,
    tabMenuActive,
    rows,
    category,
    factoryCode,
    building,
    orderCode,
  ]);

  useEffect(() => {
    if (importData3) {
      dispatch(
        getOrder({
          page: (first + rows) / rows,
          limit: rows,
          current: 'production',
        })
      );
      dispatch(setDataNull2());
    }
  }, [dispatch, first, rows, importData3]);

  useEffect(() => {
    const updateDataTable = () => {
      if (orderData) {
        setDataTable(orderData.data.products.items);
      }
    };
    updateDataTable();
  }, [orderData]);

  useEffect(() => {
    if (isDropdownOpen) {
      dispatch(
        getBuilding({
          page: 1,
          limit: 1000,
        })
      );
    }
  }, [isDropdownOpen, dispatch]);

  useEffect(() => {
    if (dataBuilding?.data?.items) {
      const buildings = dataBuilding.data.items.map((item) => ({
        label: item.name,
        value: item.buildingCode,
      }));
      setBuildingOptions(removeDuplicates(buildings, 'value'));
    }
  }, [dataBuilding]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
  };

  const quantityTemplate = (rowData) => {
    return formatNumber(rowData.quantity);
  };

  const handleBuildingChange = (e) => {
    setBuilding(e.target.value);
    setBuildingValue(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    debounce(() => setCategory(e.target.value), 'category');
  };

  const handleFactoryCodeChange = (e) => {
    setFactoryCodeValue(e.target.value);
    debounce(() => setFactoryCode(e.target.value), 'factoryCode');
  };

  const handleOrderCodeChange = (e) => {
    setOrderCodeValue(e.target.value);
    debounce(() => setOrderCode(e.target.value), 'orderCode');
  };

  const debounce = (callback, field) => {
    if (debounceTimeouts[field]) {
      clearTimeout(debounceTimeouts[field]);
    }

    const timeout = setTimeout(() => {
      callback();
      setDebounceTimeouts((prev) => ({ ...prev, [field]: null }));
    }, 400);

    setDebounceTimeouts((prev) => ({ ...prev, [field]: timeout }));
  };

  const removeDuplicates = (array, key) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const logDataTable = () => {
    const ID = dataBuilding?.data?.items?.find(
      (item) => item.buildingCode === building
    )?.id;
    if (ID) {
      dispatch(
        exportExcel3({
          type: 'production',
          building_id: ID,
        })
      );
    } else {
      dispatch(
        exportExcel3({
          type: 'production',
        })
      );
    }
  };

  useEffect(() => {
    if (exportExcelData3) {
      const url = `${site}/${exportExcelData3.file}`;
      setExcelFileUrl(url);
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xuất excel thành công.',
        life: 3000,
      });
      dispatch(setDataNull2());
      dispatch(resetProductionExcel());

      // Tự động tải file
      downloadLink.current.href = url;
      // downloadLink.current.download = 'exported_data.xlsx';
      downloadLink.current.click();
    }
  }, [dispatch, exportExcelData3]);

  useEffect(() => {
    if (exportExcelError3) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: exportExcelError3.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull2());
      dispatch(resetProductionExcel());
    }
  }, [dispatch, exportExcelError3]);

  const items = [];

  items.push({
    label: 'Xuất ra excel',
    icon: 'pi pi-file-excel',
    command: logDataTable,
  });

  items.push({
    label: 'In báo cáo',
    icon: 'pi pi-print',
    command: () => exportQuantityPDF(dataTable),
  });

  const handleClick = (event) => {
    menuRight.current.show(event);
  };

  const imageTemplate = (rowData) => {
    return (
      <Image
        src={rowData?.categoryImage || defaultImageUrl}
        alt='Category Image'
        imageStyle={{ borderRadius: '20%', width: '40px', height: '40px' }}
        preview
        onError={(e) => (e.target.src = defaultImageUrl)}
      />
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <a
        ref={downloadLink}
        style={{ display: 'none' }}
      />
      <div className='flex flex-column md:flex-row md:justify-content-end mb-3'>
        <Button
          label={loadingExportExcel ? 'Đang xuất...' : 'Thao tác'}
          popup='true'
          icon={
            loadingExportExcel
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-sort-down-fill'
          }
          disabled={loadingExportExcel}
          onClick={handleClick}
          aria-controls='popup_menu_right'
          aria-haspopup='true'
          iconPos='right'
        />
        <Menu
          model={items}
          popup
          ref={menuRight}
          id='popup_menu_right'
          popupAlignment='right'
        />
      </div>
      <div className='search-container pb-3'>
        <IconField className='search-item'>
          <Dropdown
            placeholder='Lọc công trình'
            options={buildingOptions}
            onChange={handleBuildingChange}
            value={buildingValue}
            filter
            emptyFilterMessage='Không có dữ liệu'
            emptyMessage='Không có dữ liệu'
            showClear
            className='w-full border-0'
            onClick={handleDropdownOpen}
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={orderCodeValue}
            placeholder='Mã đơn hàng'
            className='w-full border-0'
            onChange={handleOrderCodeChange}
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={categoryValue}
            onChange={handleCategoryChange}
            placeholder='Lọc hạng mục'
            className='w-full border-0'
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={factoryCodeValue}
            onChange={handleFactoryCodeChange}
            placeholder='Lọc mã nhà máy'
            className='w-full border-0'
          />
        </IconField>
      </div>
      <div className='bg-white border-round-md shadow-1 px-3'>
        {orderData && orderData.data ? (
          <>
            <div>
              <TabMenu
                model={orderData?.data.listStatus.map((item, index) => ({
                  ...item,
                  template: () =>
                    tabMenuTemplate(
                      item,
                      index,
                      tabMenuActive,
                      setTabmenuActive
                    ),
                }))}
                activeIndex={tabMenuActive}
                onTabChange={(e) => setTabmenuActive(e.index)}
                pt={{ menuitem: 'p-0' }}
              />
            </div>
            <div>
              <DataTable
                value={dataTable}
                emptyMessage='Không có dữ liệu'
                scrollHeight='62vh'
                scrollable
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                size='small'>
                <Column
                  header='STT'
                  body={(rowData, options) => (
                    <div className='text-center'>
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  frozen={window.innerWidth > 768}
                  style={{ width: '3%', minWidth: '60px' }}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Tên công trình'
                  field='Building.name'
                  filter
                  frozen={window.innerWidth > 768}
                  filterPlaceholder='Tìm kiếm tên công trình'
                  style={{ width: '5%', minWidth: '200px' }}></Column>
                <Column
                  header='Mã ĐH'
                  field='orderCode'
                  filter
                  frozen={window.innerWidth > 768}
                  filterPlaceholder='Tìm kiếm mã đơn hàng'
                  style={{ width: '6%', minWidth: '100px' }}></Column>
                <Column
                  header='Mã nhà máy'
                  field='factoryCode'
                  filter
                  filterPlaceholder='Tìm kiếm mã nhà máy'
                  style={{ width: '8%', minWidth: '150px' }}></Column>
                <Column
                  header='Hạng mục'
                  field='categoryName'
                  filter
                  frozen={window.innerWidth > 768}
                  filterPlaceholder='Tìm kiếm hạng mục'
                  style={{
                    width: '6%',
                    minWidth: '200px',
                    lineHeight: '1.5',
                  }}></Column>
                <Column
                  header='Hình ảnh'
                  body={imageTemplate}
                  style={{ width: '8%', height: '55px', minWidth: '70px' }}
                  frozen={window.innerWidth > 768}
                  align={'center'}></Column>

                <Column
                  header='SL'
                  field='quantity'
                  style={{ width: '3%', minWidth: '60px' }}
                  alignHeader={'center'}
                  frozen={window.innerWidth > 768}
                  body={quantityTemplate}
                  align={'center'}></Column>
                <Column
                  header='ĐVT'
                  field='unit'
                  filter
                  frozen={window.innerWidth > 768}
                  filterPlaceholder='Tìm kiếm đơn vị tính'
                  style={{ width: '3%', minWidth: '60px' }}></Column>
                <Column
                  header='Mã SP'
                  field='productCode'
                  filter
                  filterPlaceholder='Tìm kiếm mã sản phẩm'
                  style={{ width: '5%', minWidth: '150px' }}></Column>

                <Column
                  header='Kế hoạch hoàn thành'
                  field='intendFinishDate'
                  sortable
                  body={(rowData) => formatDate(rowData.intendFinishDate)}
                  style={{ width: '8%', minWidth: '120px' }}
                  alignHeader={'center'}
                  align={'center'}></Column>

                <Column
                  header='Trạng thái'
                  field='status'
                  filter
                  filterPlaceholder='Tìm kiếm trạng thái'
                  body={statusTemplate}
                  style={{ width: '12%', minWidth: '150px' }}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Ngày ra BOM'
                  style={{ width: '8%', minWidth: '150px' }}
                  field='bomProductionDate'
                  filter
                  filterPlaceholder='Tìm kiếm ngày ra BOM'
                  body={(rowData) => formatDate(rowData.bomProductionDate)}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Ngày bắt đầu SX'
                  style={{ width: '8%', minWidth: '150px' }}
                  field='startedDate'
                  filter
                  filterPlaceholder='Tìm kiếm ngày bắt đầu'
                  body={(rowData) => formatDate(rowData.startedDate)}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Hoàn thành thực tế'
                  style={{ width: '8%', minWidth: '165px' }}
                  field='endedDate'
                  filter
                  filterPlaceholder='Tìm kiếm ngày hoàn thành'
                  body={(rowData) => formatDate(rowData.endedDate)}
                  alignHeader={'center'}
                  align={'center'}></Column>

                <Column
                  header='Ghi chú'
                  field='productNote'
                  style={{ width: '6%', minWidth: '150px' }}></Column>

                <Column
                  header='Định vị'
                  body={(rowData) => (
                    <div className='text-center'>
                      <Button
                        onClick={() => handleTeamProgress(rowData)}
                        icon='pi pi-map-marker'
                        rounded
                        text
                        severity='secondary'
                        aria-label='Bookmark'
                        style={{ color: 'black' }}
                      />
                      <p>{rowData.teamWorking}</p>
                    </div>
                  )}
                  style={{
                    width: '15%',
                    minWidth: '100px',
                  }}
                  alignHeader={'center'}
                  align={'center'}></Column>

                <Column />
              </DataTable>
              <div className='card'>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orderData?.data?.products?.totalItems}
                  rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
            <ModalExcel
              onHide={() => setDialogImportVisible(false)}
              visible={dialogImportVisible}
            />
            {dialogLocationVisible && (
              <Intersection
                visible={dialogLocationVisible}
                onHide={() => setDialogLocationVisible(false)}
                factoryCode={selectFactoryCode}
              />
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  );
}
