import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardQuantityData: null,
    error: null,
    loading: false,
    excelData: null,
    errorExcelData: null,
};

const dashboardQuantitySlice = createSlice({
    name: "dashboardQuantity",
    initialState,
    reducers: {
        getDashboardQuantity: (state) => {
            state.loading = true;
        },
        getDashboardQuantitySuccess: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = action.payload;
            state.error = null;
        },
        getDashboardQuantityFail: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = null;
            state.error = action.payload;
        },
        downloadExcelQuantity: (state, action) => {
            state.loading = true;
        },
        downloadExcelQauntitySuccess: (state, action) => {
            state.loading = false;
            state.excelData = action.payload;
            state.errorExcelData = null;
        },
        downloadExcelQuantityFail: (state, action) => {
            state.loading = false;
            state.excelData = null;
            state.errorExcelData = action.payload;
        },
        setExcelDataQuantityNull: (state) => {
            state.excelData = null;
            state.errorExcelData = null;
        },
    },
});

export const {
    getDashboardQuantity,
    getDashboardQuantityFail,
    getDashboardQuantitySuccess,
    downloadExcelQauntitySuccess,
    downloadExcelQuantity,
    downloadExcelQuantityFail,
    setExcelDataQuantityNull,
} = dashboardQuantitySlice.actions;

export default dashboardQuantitySlice.reducer;
