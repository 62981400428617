import React, { useState, useEffect, useContext, useRef } from "react";
import "./ReportPlan.scss";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDashboardPlan } from "../../store/dashboardPlan/slice";
import { site } from "../../api/url_helper";
import LoadingSpinner from "../Report/LoadingSpinner";
import "../../assets/scss/theme-base/dashboard.css";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { exportPlanPDF } from "./ExportPlanPDF";
import { imageTemplate } from "../Report/ProductInprogress";
import { DataContext } from "../../contexts/data/DataProvider";
import { FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import {
    downloadExcelQuantity,
    setExcelDataQuantityNull,
} from "../../store/dashboardQuantity/slice";
import { formatDateApi } from "../../feature/formatDateApi";

export default function ReportPlan() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const [loading, setLoading] = useState(false);
    const [tabMenuActive, setTabmenuActive] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [nameBuilding, setNameBuilding] = useState("");
    const [excelFileUrl, setExcelFileUrl] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    //format số lượng
    function formatQuantity(quantity) {
        if (quantity !== undefined && quantity !== null) {
            let formatted = quantity.toString();

            formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formatted;
        } else {
            return 0;
        }
    }

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };

    const DashboardPlanData = useSelector(
        (state) => state.DashboardPlan.dashboardPlanData,
        shallowEqual
    );

    useEffect(() => {
        let status = "Tất cả";
        if (DashboardPlanData) {
            status = DashboardPlanData.data.listStatus[tabMenuActive]?.label;
        }
        if (startDate && endDate) {
            dispatch(
                getDashboardPlan({
                    page: (first + rows) / rows,
                    limit: rows,
                    status,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        tabMenuActive,
        rows,
        first,
    ]);

    useEffect(() => {
        setDataTable(DashboardPlanData?.data?.result?.items);
    }, [DashboardPlanData]);

    useEffect(() => {
        const updateDataTable = () => {
            const status =
                DashboardPlanData?.data?.listStatus[tabMenuActive]?.label;
            const filteredData =
                status === "Tất cả"
                    ? DashboardPlanData?.data?.result?.items
                    : DashboardPlanData?.data?.result?.items.filter(
                          (item) => item.status === status
                      );
            setDataTable(filteredData);
        };
        updateDataTable();
    }, [tabMenuActive]);

    //export excel

    const { buildingData, exportExcelQuantity, exportExcelQuantityError } =
        useSelector(
            (state) => ({
                exportExcelQuantityError:
                    state.DashboardQuantity.errorExcelData,
                exportExcelQuantity: state.DashboardQuantity.excelData,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelQuantity({
                    type: "plan-statistics",
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelQuantity({
                    type: "plan-statistics",
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            setExcelFileUrl(url);
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <HeaderReport
                building={building}
                orderCode={orderCode}
                category={category}
                factoryCode={factoryCode}
                setBuilding={setBuilding}
                setOrderCode={setOrderCode}
                setCategory={setCategory}
                setFactoryCode={setFactoryCode}
                setStartDateOutput={setStartDate}
                setEndDateOutput={setEndDate}
                exportClick={(e) =>
                    exportPlanPDF(
                        "save",
                        FormatDate(startDate),
                        FormatDate(endDate),
                        dataTable,
                        nameBuilding
                    )
                }
                printReport={(e) =>
                    exportPlanPDF(
                        "print",
                        FormatDate(startDate),
                        FormatDate(endDate),
                        dataTable,
                        nameBuilding
                    )
                }
                exportExcel={exportExcelHandle}
            />
            <div className="bg-white border-round-md shadow-1 px-3 mt-3">
                <div>
                    <TabMenu
                        model={DashboardPlanData?.data?.listStatus.map(
                            (item, index) => ({
                                ...item,
                                template: () =>
                                    tabMenuTemplate(
                                        item,
                                        index,
                                        tabMenuActive,
                                        setTabmenuActive
                                    ),
                            })
                        )}
                        activeIndex={tabMenuActive}
                        onTabChange={(e) => setTabmenuActive(e.index)}
                        pt={{ menuitem: "p-0" }}
                    />
                </div>
                {DashboardPlanData && DashboardPlanData.data ? (
                    <div>
                        <DataTable
                            value={dataTable}
                            rowClassName="custom-row-class"
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            size="small"
                            emptyMessage="Không có dữ liệu"
                            scrollable
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            scrollHeight="50vh"
                        >
                            <Column
                                header="STT"
                                body={(rowData, options) => (
                                    <div className="text-center">
                                        {first + options.rowIndex + 1}
                                    </div>
                                )}
                                style={{ width: "3%" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Tên công trình"
                                field="Building.name"
                                style={{ minWidth: "150px" }}
                                filter
                                filterPlaceholder="Search by name"
                            ></Column>
                            <Column
                                header="Mã ĐH"
                                field="orderCode"
                                style={{ minWidth: "100px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã nhà máy"
                                field="factoryCode"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hạng mục"
                                field="categoryName"
                                style={{ lineHeight: "1.5", minWidth: "200px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hình ảnh"
                                body={imageTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="SL"
                                field="quantity"
                                style={{ minWidth: "70px" }}
                            ></Column>
                            <Column
                                header="ĐVT"
                                field="unit"
                                style={{ minWidth: "50px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã sản phẩm"
                                field="productCode"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>

                            <Column
                                header="Kế hoạch hoàn thành"
                                field="intendFinishDate"
                                style={{ minWidth: "120px" }}
                                body={(rowData) => (
                                    <span>
                                        {FormatDate(rowData.intendFinishDate)}
                                    </span>
                                )}
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Trạng thái"
                                field="status"
                                body={statusTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Số lượng kế hoạch"
                                field="totalProductionPlan"
                                body={(rowData) => (
                                    <div>
                                        {formatQuantity(
                                            rowData.totalProductionPlan
                                        )}
                                    </div>
                                )}
                                style={{ minWidth: "100px" }}
                                alignHeader={"center"}
                                align={"center"}
                                footer={
                                    <div className="text-base">
                                        {formatQuantity(
                                            DashboardPlanData?.data?.totals
                                                ?.totalProductionPlan
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="Số lượng giao thực tế"
                                field="totalActualDelivery"
                                style={{ minWidth: "120px" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Định vị"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() =>
                                                handleTeamProgress(rowData)
                                            }
                                            icon="pi pi-map-marker"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="Bookmark"
                                            style={{ color: "black" }}
                                        />
                                        <p>{rowData.teamWorking}</p>
                                    </div>
                                )}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="Ghi chú"
                                field="productNote"
                                style={{ minWidth: "200px" }}
                            ></Column>
                        </DataTable>
                        <div className="card">
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={
                                    DashboardPlanData?.data?.result?.totalItems
                                }
                                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                                onPageChange={onPageChange}
                            />
                        </div>
                        {dialogLocationVisible && (
                            <Intersection
                                visible={dialogLocationVisible}
                                onHide={() => setDialogLocationVisible(false)}
                                factoryCode={selectFactoryCode}
                            />
                        )}
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </div>
    );
}
